import React, { useState, useMemo, useEffect } from 'react';
import ToggleSwitch from './ToggleButton';

const CHECKBOXES = [
  { value: 45, label: 'Corte PURO (R$ 45)', description: 'Você escolhe o momento para criar o corte...' },
  { value: 89, label: 'Corte SELEÇÃO (R$ 89)', description: 'Nós escolhemos o momento do episódio...' },
  { value: 120, label: 'Corte ESTRATÉGICO (R$ 120)', description: 'Análise minuciosa para identificar momentos...' },
  { value: 85, label: 'CORTE MONTAGEM (R$ 85)', description: 'Análise para identificar momentos...' },
  { value: 190, label: 'CORTE PRODUZIDO (R$ 190)', description: 'Cortes cuidadosamente selecionados...' },
];

const PodcastCalculator = ({ onNext }) => {
  const [tempo, setTempo] = useState(0);
  const [fracao, setFracao] = useState(false);
  const [semCortes, setSemCortes] = useState(false);
  const [selectedCortes, setSelectedCortes] = useState({});
  const [fracaoValue, setFracaoValue] = useState(0);
  const [showDescriptions, setShowDescriptions] = useState({});

  const handleToggleFracao = () => {
    if (tempo === 0) {
      alert('Por favor, selecione um plano de horas antes de adicionar fração.');
      return;
    }
    setFracao(prev => !prev);
  };

  const handleToggleSemCortes = () => {
    if (tempo === 0) {
      alert('Por favor, selecione um plano de horas antes de escolher a opção "Sem Cortes".');
      return;
    }
    setSemCortes(prev => {
      if (!prev) {
        setSelectedCortes({});
      }
      return !prev;
    });
  };

  const handleQuantidadeChange = (event, label) => {
    if (tempo === 0) {
      alert('Por favor, selecione um plano de horas antes de escolher os cortes.');
      return;
    }
    const value = parseInt(event.target.value, 10);
    setSelectedCortes(prev => ({
      ...prev,
      [label]: value,
    }));
  };

  const handleTempoChange = (event) => {
    const selectedTempo = parseFloat(event.target.value);
    setTempo(selectedTempo);
  };

  const total = useMemo(() => {
    const corteTotal = Object.keys(selectedCortes).reduce((sum, key) => {
      const corte = CHECKBOXES.find(c => c.label === key);
      return sum + (corte ? corte.value * selectedCortes[key] : 0);
    }, 0);
    
    return tempo + (fracao ? fracaoValue : 0) + corteTotal;
  }, [tempo, fracao, selectedCortes, fracaoValue]);

  const handleSubmit = () => {
    if (tempo === 0) {
      alert('Por favor, selecione um plano de horas antes de prosseguir.');
      return;
    }
  
    onNext({
      tempo,
      fracao,
      semCortes,
      selectedCortes,
      total,
    });
  };
  
  useEffect(() => {
    if (tempo === 400) {
      setFracaoValue(175);
    } else if (tempo === 700) {
      setFracaoValue(150);
    } else if (tempo === 900) {
      setFracaoValue(125);
    } else {
      setFracaoValue(0);
    }
  }, [tempo]);

  return (
    <div className="calculator-container">
      <h2>Calculadora de Podcast</h2>
      <div className="form-group">
        <label htmlFor="tempo">Tempo:</label>
        <select id="tempo" value={tempo} onChange={handleTempoChange}>
          <option value="0">Selecione</option>
          <option value="400">1 HORA - R$ 400</option>
          <option value="700">2 HORAS (R$ 350/hora) - R$ 700</option>
          <option value="900">3 HORAS (R$ 300/hora) - R$ 900</option>
        </select>
      </div>
      <h2>TOLERÂNCIA: 15 MINUTOS</h2>

      <div className="form-group">
        <ToggleSwitch
          isActive={fracao}
          onClick={handleToggleFracao}
          label={`FRAÇÃO EXTRA (R$ ${fracaoValue}) - Tempo adicional ao Pacote de Horas com máximo de 30 min`}
        />
      </div>

      <div className="form-group">
        <ToggleSwitch
          isActive={semCortes}
          onClick={handleToggleSemCortes}
          label="Sem Cortes"
        />
      </div>

      {!semCortes && (
        <div>
          {CHECKBOXES.map(({ label }) => (
            <div key={label} className="corte-input">
              <span>{label}</span>
              <select
                value={selectedCortes[label] || 0}
                onChange={(e) => handleQuantidadeChange(e, label)}
              >
                <option value="0">0</option>
                {[...Array(21).keys()].map(i => (
                  <option key={i} value={i}>{i}</option>
                ))}
              </select>
            </div>
          ))}
        </div>
      )}

      <div className="form-group">
        <h3>Total: R$ {total.toFixed(2)}</h3>
      </div>

      <button onClick={handleSubmit}>
        Próximo
      </button>
    </div>
  );
};

export default PodcastCalculator;
