import React, { useState } from 'react';

const Step3 = ({ onNext, onPrevious }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleOptionChange = (option) => {
    setSelectedOptions(prev => 
      prev.includes(option) ? prev.filter(o => o !== option) : [...prev, option]
    );
  };

  const handleSubmit = () => {
    onNext(selectedOptions);
  };

  return (
    <div className='calculator-container'>
      <h2>Passo 3: Escolha Mais Opções</h2>
      <label>
        <input type="checkbox" onChange={() => handleOptionChange('Opção X')} />
        Opção X
      </label>
      <label>
        <input type="checkbox" onChange={() => handleOptionChange('Opção Y')} />
        Opção Y
      </label>
      <label>
        <input type="checkbox" onChange={() => handleOptionChange('Opção Z')} />
        Opção Z
      </label>
      <button onClick={onPrevious}>Voltar</button>
      <button onClick={handleSubmit}>Próximo</button>
    </div>
  );
};

export default Step3;
