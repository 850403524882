// ToggleSwitch.js
import React from 'react';
import './PodcastCalculator.css'; // Create this CSS file for styles

const ToggleSwitch = ({ isActive, onClick, label }) => {
  return (
    <div className="toggle-switch" onClick={onClick}>
      <input type="checkbox" checked={isActive} readOnly />
      <span className="slider"></span>
      <span className="label">{label}</span>
    </div>
  );
};

export default ToggleSwitch;
