import React, { useState } from 'react';
import PodcastCalculator from './components/PodcastCalculator';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import Resumo from './components/Resumo';
import './App.css';
import logo from './assets/podfast-logo.png'

const App = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    podcastData: {
      tempo: 0,
      fracao: false,
      semCortes: false,
      selectedCortes: {},
      total: 0,
    },
    step2Data: [],
    step3Data: [],
  });

  const handleNext = (data) => {
    setFormData(prev => ({ ...prev, ...data }));
    setStep(prevStep => prevStep + 1);
  };

  const handlePrevious = () => {
    setStep(prevStep => prevStep - 1);
  };

  return (
    <div className='app-container'>
      <img className='logoo' src={logo} alt='Logo'></img>

      <div className="divider"></div>
      
      <h2 className="planos-investimentos">Planos e Investimentos</h2>

      <div className="info-1">
        <p style={{ color: 'white', textAlign: 'center', fontSize: '20px' }}>
          Preços promocionais disponíveis somente para datas<br /> específicas de acordo com nível de lotação na agenda.
        </p>
        <ul style={{ color: 'white', textAlign: 'center', fontSize: '18px', listStyleType: 'none' }}>
          <li>✔ Captação de vídeo em alta resolução</li>
          <li>✔ Captação de áudio em alta fidelidade</li>
          <li>✔ Edição/finalização gravação completa</li>
        </ul>
      </div>

      {step === 1 && <PodcastCalculator onNext={(data) => handleNext({ podcastData: data })} />}
      {step === 2 && <Step2 onNext={(data) => handleNext({ step2Data: data })} onPrevious={handlePrevious} />}
      {step === 3 && <Step3 onNext={(data) => handleNext({ step3Data: data })} onPrevious={handlePrevious} />}
      {step === 4 && <Resumo data={formData} onEdit={handlePrevious} />}
    </div>
  );
};

export default App;
