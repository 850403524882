import React from 'react';

const tempoOptions = {
  400: "1 HORA (R$ 400)",
  700: "2 HORAS (R$ 700)",
  900: "3 HORAS (R$ 900)"
};

const Resumo = ({ data, onEdit }) => {
  const { podcastData, step2Data, step3Data } = data;

  return (
    <div className='calculator-container'>
      <h2>Resumo das Escolhas</h2>
      <p><strong>Tempo Selecionado:</strong> {tempoOptions[podcastData.tempo] || 'Nenhum plano selecionado'}</p>
      <p><strong>Fração Extra:</strong> {podcastData.fracao ? 'Sim' : 'Não'}</p>
      <p><strong>Sem Cortes:</strong> {podcastData.semCortes ? 'Sim' : 'Não'}</p>
      <h3>Cortes Selecionados:</h3>
      <ul>
        {Object.entries(podcastData.selectedCortes).length > 0 ? (
          Object.entries(podcastData.selectedCortes).map(([label, quantity]) => (
            <li key={label}>{label}: {quantity}</li>
          ))
        ) : (
          <li>Nenhum corte selecionado.</li>
        )}
      </ul>
      <p><strong>Total:</strong> R$ {podcastData.total.toFixed(2)}</p>

      <h3>Opções do Step 2:</h3>
      <ul>
        {step2Data.length > 0 ? (
          step2Data.map(option => (
            <li key={option}>{option}</li>
          ))
        ) : (
          <li>Nenhuma opção selecionada no Step 2.</li>
        )}
      </ul>

      <h3>Opções do Step 3:</h3>
      <ul>
        {step3Data.length > 0 ? (
          step3Data.map(option => (
            <li key={option}>{option}</li>
          ))
        ) : (
          <li>Nenhuma opção selecionada no Step 3.</li>
        )}
      </ul>

      <button onClick={onEdit}>Editar</button>
    </div>
  );
};

export default Resumo;
