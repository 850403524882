import React, { useState } from 'react';

const Step2 = ({ onNext, onPrevious }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleOptionChange = (option) => {
    setSelectedOptions(prev => 
      prev.includes(option) ? prev.filter(o => o !== option) : [...prev, option]
    );
  };

  const handleSubmit = () => {
    onNext(selectedOptions);
  };

  return (
    <div className='calculator-container'>
      <h2>Passo 2: Escolha Opções</h2>
      <label>
        <input type="checkbox" onChange={() => handleOptionChange('Opção A')} />
        Opção A
      </label>
      <label>
        <input type="checkbox" onChange={() => handleOptionChange('Opção B')} />
        Opção B
      </label>
      <label>
        <input type="checkbox" onChange={() => handleOptionChange('Opção C')} />
        Opção C
      </label>
      <button onClick={onPrevious}>Voltar</button>
      <button onClick={handleSubmit}>Próximo</button>
    </div>
  );
};

export default Step2;
